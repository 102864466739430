<template>
	<div class="home">
		<header-view></header-view>
		<div class="content">
			<img src="../assets/img/text1.png" alt="" class="content-top">
			<img src="../assets/img/text2.png" alt="" class="content-middle">
			<div class="content-bottom" @click="toIosApp">Read, Listen, Watch <span>with HeardLy</span></div>
		</div>
		<footer-view></footer-view>
	</div>
</template>

<script>
	// import * as api from '@/api'
	export default {
		data() {
			return {

			}
		},
		methods: {
			toIosApp() {
				this.$router.push({
					path: '/getSystem'
				})
				// window.location.href = 'https://apps.apple.com/us/app/heardly-lesstimemoreknowledge/id6473803023'
			}
		},
		mounted() {

		},
		beforeDestroy() {}
	}
</script>
<style lang="less" scoped>
	.home {
		background: #F7F7F7;
		padding-top: 87px;

		.content {
			padding: 4.75rem 2.208333rem 0;
			color: #1A1C2E;
			height: 100vh;
			background: url(../assets/img/bg2.png) no-repeat;
			background-size: cover;

			.content-top {
				width: 19.5rem;
				display: block;
			}

			.content-middle {
				width: 26.5rem;
				margin-top: 0.5rem;
				display: block;
			}

			.content-bottom {
				background: #333333;
				border-radius: 0.666667rem;
				display: inline-block;
				color: #FDFDFD;
				font-size: 1.083333rem;
				padding: 0.75rem 1.25rem;
				margin-top: 3.5rem;
				cursor: pointer;

				span {
					font-weight: 600;
				}
			}
		}


	}

	@media (min-width:960px) {
		// .top{
		// 	width: 1200px !important;
		// 	margin: 0 auto;
		// }
	}
</style>